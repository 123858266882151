import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Classes, Colors } from "@blueprintjs/core";
import Progress from "react-progress";

import { useCurrentSession } from "@/components/helpers/custom-hooks/use-current-session";
import { useWorkspaceSlug } from "@/components/helpers/custom-hooks/use-workspace-slug";
import { Box, Flex } from "@/components/layout/flexbox";
import { SizeBreakpoint } from "@/components/layout/Media";
import PublicNav from "@/components/nav/PublicNav";
import { ExploreDetailSkeleton as ExploreDetailSkeletonInner } from "@/components/pages_flexible/explore/detail/ExploreDetailSkeleton";
import DashboardLoader from "@/components/pages_logged_in/coaching/DashboardLoader";
import ProgramDetailsLoader from "@/components/pages_logged_in/program/components/ProgramDetailsLoader";
import ProgramListLoader from "@/components/pages_logged_in/program/pages/program-list/ProgramListLoader";
import RoleplayContent from "@/components/pages_logged_in/roleplays/details/RoleplayContent";
import { RoleplaysListContainer } from "@/components/pages_logged_in/roleplays/RoleplaysListContainer";
import { ScenariosPageContent } from "@/components/pages_logged_in/roleplays/scenarios/ScenariosPageContainer";
import { MeetingSkeleton } from "@/components/pages_logged_in/session/MeetingSkeleton";
import WorkspaceNav from "@/components/pages_logged_in/workspace/nav";
import { Clearfix, Col, Container, Row } from "@/components/pieces/bootstrap";
import ArticleSkeleton from "@/components/pieces/learn/ArticleSkeleton";
import LearnBoardSkeletonContainer from "@/components/pieces/learn/LearnBoardSkeleton";
import LearnHeader from "@/components/pieces/learn/LearnHeader";
import { CoachProfileSkeleton } from "@/components/pieces/talent-agents/CoachProfile";
import TodoLoadingIndicator from "@/components/pieces/todos/TodoLoadingIndicator";
import {
  LIGHTEST_BACKGROUND,
  SIDE_NAV_WIDTH,
  STANDARD_APP_MAX_WIDTH,
  STANDARD_NAV_HEIGHT,
  STANDARD_SIZE_NAV_BREAKPOINT,
} from "@/css/constants";

interface LoadingContextType {
  active: boolean;
  setActive: (active: boolean) => void;
  percent: number;
  setPercent: (percent: number) => void;
}

const IndicatorContext = createContext<LoadingContextType>({
  active: false,
  setActive: () => {},
  percent: 0,
  setPercent: () => {},
});

export function PageLoadingProvider({ children }) {
  const [active, setActive] = useState(false);
  const [percent, setPercent] = useState(0);

  const value = useMemo(
    () => ({ active, setActive, percent, setPercent }),
    [active, setActive, percent, setPercent],
  );
  return (
    <IndicatorContext.Provider value={value}>
      {children}
    </IndicatorContext.Provider>
  );
}

let timeoutRef = null;

export function PageLoadingIndicator({
  type = "full",
  visibleSideNav = false,
  flexiblePage = false,
  extraProps = null,
}) {
  const { setActive, setPercent } = useContext(IndicatorContext);
  const { currentSessionLink } = useCurrentSession();

  const showSideNav =
    (!flexiblePage && visibleSideNav) ||
    (flexiblePage && visibleSideNav && currentSessionLink);

  useEffect(() => {
    setActive(true);
    return () => {
      setActive(false);
      clearTimeout(timeoutRef);
      timeoutRef = setTimeout(() => setPercent(0), 1000);
    };
  });

  return (
    <Indicator
      type={type}
      visibleSideNav={showSideNav}
      extraProps={extraProps}
    />
  );
}

export function Indicator({ type, visibleSideNav, extraProps }) {
  const mounted = useRef(false);
  const { active, percent, setPercent } = useContext(IndicatorContext);
  const workspaceSlug = useWorkspaceSlug();

  useEffect(() => {
    mounted.current = true;
    setTimeout(() => {
      if (!mounted.current) {
        return;
      }
      const newPercent = percent < 95 ? percent + getIncrement(percent) : 95;
      setPercent(newPercent);
    }, 200);
    return () => {
      mounted.current = false;
    };
  });

  const Skeleton = Components[type];

  return active ? (
    <>
      <Progress percent={percent} />
      <Skeleton
        workspace={workspaceSlug}
        visibleSideNav={visibleSideNav}
        extraProps={extraProps}
      />
    </>
  ) : null;
}

interface PageLoadingContainerProps {
  visibleSideNav: boolean;
  children: ReactNode;
  sizeNavBreakpoint?: SizeBreakpoint;
}

export function PageLoadingContainer({
  visibleSideNav,
  children,
  sizeNavBreakpoint = STANDARD_SIZE_NAV_BREAKPOINT,
}: PageLoadingContainerProps) {
  return (
    <>
      {visibleSideNav ? (
        <WorkspaceNav sizeNavBreakpoint={sizeNavBreakpoint} />
      ) : (
        <PublicNav />
      )}
      <Box
        mt={STANDARD_NAV_HEIGHT}
        ml={[0, 0, visibleSideNav ? SIDE_NAV_WIDTH : 0]}
      >
        {children}
      </Box>
    </>
  );
}

const Components = {
  full: FullSkeleton,
  marketing: MarketingPageSkeleton,
  compact: CompactSkeleton,
  dashboard: DashboardSkeleton,
  userDashboard: UserDashboardSkeleton,
  cards: CardSkeleton,
  learnBoard: LearnBoardLoadingIndicator,
  learnArticle: LearnArticleSkeleton,
  profile: ProfileSkeleton,
  meeting: MeetingSkeleton,
  exploreDetail: ExploreDetailSkeleton,
  roleplays: RoleplaysSkeleton,
  roleplayDetail: RoleplayDetailSkeleton,
  roleplayScenarios: RoleplayScenariosSkeleton,
  programs: ProgramsSkeleton,
  programDetails: ProgramDetailsSkeleton,
};

export function FullSkeleton() {
  return (
    <div className="content-page">
      <div className="hero">
        <div className="hero-content text-center" />
      </div>
      <Clearfix />
      <Container mt="4rem" mb="4rem">
        <Row>
          <Col width="50%">
            <div
              className={Classes.SKELETON}
              style={{ width: "100%", height: 400 }}
            />
          </Col>
          <Col width="50%">
            <div
              className={Classes.SKELETON}
              style={{ width: "100%", height: 400 }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function MarketingPageSkeleton({ visibleSideNav }) {
  return (
    <>
      <PageLoadingContainer visibleSideNav={visibleSideNav}>
        <Box px={[2, 4, 4]}>
          <FullSkeleton />
        </Box>
      </PageLoadingContainer>
    </>
  );
}

function CompactSkeleton() {
  return (
    <div className="content-page">
      <div className="hero">
        <div className="hero-content text-center">
          <Box my="4rem">
            <div
              className={`inline ${Classes.SKELETON}`}
              style={{ width: 200, maxWidth: "100%", height: 70 }}
            />
            <Box mt="1rem">
              <div
                className={`inline ${Classes.SKELETON}`}
                style={{ width: 350, maxWidth: "100%", height: 70 }}
              />
            </Box>
            <Box mt="2rem">
              <div
                className={`inline ${Classes.SKELETON}`}
                style={{ width: 400, maxWidth: "100%", height: 200 }}
              />
            </Box>
            <Box mt="4rem">
              <div
                className={`inline ${Classes.SKELETON}`}
                style={{ width: 300, maxWidth: "100%", height: 20 }}
              />
            </Box>

            <Box mt="4rem">
              <div
                className={`inline ${Classes.SKELETON}`}
                style={{ width: 300, maxWidth: "100%", height: 200 }}
              />
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}

function UserDashboardSkeleton({ extraProps }) {
  const { selectedTab } = extraProps || {};
  return (
    <>
      <PageLoadingContainer visibleSideNav={true}>
        <DashboardLoader selectedTab={selectedTab} />
      </PageLoadingContainer>
    </>
  );
}

function ProgramsSkeleton() {
  return (
    <>
      <PageLoadingContainer visibleSideNav={true}>
        <ProgramListLoader />
      </PageLoadingContainer>
    </>
  );
}

function ProgramDetailsSkeleton() {
  return (
    <>
      <PageLoadingContainer visibleSideNav={true}>
        <ProgramDetailsLoader />
      </PageLoadingContainer>
    </>
  );
}

function RoleplaysSkeleton() {
  return (
    <>
      <PageLoadingContainer visibleSideNav={true}>
        <RoleplaysListContainer pageLoading={true} />
      </PageLoadingContainer>
    </>
  );
}

function RoleplayDetailSkeleton() {
  return (
    <>
      <PageLoadingContainer visibleSideNav={true}>
        <RoleplayContent loading={true} />
      </PageLoadingContainer>
    </>
  );
}

function RoleplayScenariosSkeleton() {
  return (
    <>
      <PageLoadingContainer visibleSideNav={true}>
        <ScenariosPageContent pageLoading={true} />
      </PageLoadingContainer>
    </>
  );
}

function DashboardSkeleton({ visibleSideNav }: { visibleSideNav: boolean }) {
  return (
    <>
      <PageLoadingContainer visibleSideNav={visibleSideNav}>
        <Box
          m="0 auto"
          style={{ maxWidth: STANDARD_APP_MAX_WIDTH }}
          pt={[2, 2, 2, 4]}
          pb={4}
          px={3}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <h3 style={{ margin: 0 }} className={Classes.SKELETON}>
              Your Roadmap
            </h3>
            <Box className={Classes.SKELETON} height={44} width={44} />
          </Flex>
          <TodoLoadingIndicator />
        </Box>
      </PageLoadingContainer>
    </>
  );
}

function CardSkeleton() {
  return (
    <>
      <WorkspaceNav />
      <Box
        ml={[0, 0, SIDE_NAV_WIDTH]}
        mt={STANDARD_NAV_HEIGHT}
        maxWidth={STANDARD_APP_MAX_WIDTH}
        style={{ position: "relative" }}
      >
        <Box px={3} py={1} className="bbs">
          <LoadingRow height={34} />
        </Box>
        <Box px={3} width={1}>
          <LoadingMatrix height={150} rows={4} />
        </Box>
      </Box>
    </>
  );
}

function LearnBoardLoadingIndicator({
  visibleSideNav,
}: {
  visibleSideNav: boolean;
}) {
  return (
    <>
      {visibleSideNav ? <WorkspaceNav /> : <PublicNav />}
      <Box
        style={{
          backgroundColor: LIGHTEST_BACKGROUND,
          color: Colors.WHITE,
          minHeight: "100vh",
        }}
        mt={STANDARD_NAV_HEIGHT}
        ml={[0, 0, visibleSideNav ? SIDE_NAV_WIDTH : 0]}
      >
        <LearnHeader />
        <Flex justifyContent="center">
          <Box p={3} mt={4}>
            <LearnBoardSkeletonContainer />
          </Box>
        </Flex>
      </Box>
    </>
  );
}
function LearnArticleSkeleton({ visibleSideNav }: { visibleSideNav: boolean }) {
  return (
    <>
      {visibleSideNav ? <WorkspaceNav /> : <PublicNav />}
      <Box
        mt={STANDARD_NAV_HEIGHT}
        ml={[0, 0, 0, visibleSideNav ? SIDE_NAV_WIDTH : 0]}
      >
        <ArticleSkeleton />
      </Box>
    </>
  );
}

function ProfileSkeleton({ visibleSideNav }: { visibleSideNav: boolean }) {
  return (
    <>
      <PageLoadingContainer visibleSideNav={visibleSideNav}>
        <Flex flexDirection="column" alignItems="center">
          <CoachProfileSkeleton />
        </Flex>
      </PageLoadingContainer>
    </>
  );
}

function ExploreDetailSkeleton({
  visibleSideNav,
}: {
  visibleSideNav: boolean;
}) {
  return (
    <>
      <PageLoadingContainer visibleSideNav={visibleSideNav}>
        <ExploreDetailSkeletonInner />
      </PageLoadingContainer>
    </>
  );
}

function LoadingMatrix({ height, rows }: { height: number; rows: number }) {
  return (
    <>
      {Array.from(Array(rows).keys()).map((y) => (
        <LoadingRow key={y} height={height} />
      ))}
    </>
  );
}

function LoadingRow({ height }: { height: number }) {
  return (
    <Flex my={3} width={1} flexWrap="wrap" mx={-2}>
      {[1, 2, 3, 4].map((x) => (
        <Box key={x} px={2} height={height} width={[1, 1 / 2, 1 / 4]}>
          <Box
            className={Classes.SKELETON}
            height="100%"
            width={1}
            style={{ borderRadius: 6 }}
          />
        </Box>
      ))}
    </Flex>
  );
}

function getIncrement(percent: number) {
  let ratio = 5;
  if (percent > 10) {
    ratio = 10;
  } else if (percent > 20) {
    ratio = 50;
  } else if (percent > 30) {
    ratio = 500;
  } else if (percent > 40) {
    ratio = 5000;
  } else if (percent > 50) {
    ratio = 50000;
  } else if (percent > 60) {
    ratio = 500000;
  } else if (percent > 70) {
    ratio = 5000000;
  } else if (percent > 80) {
    ratio = 50000000;
  }

  return (100 - percent) / ratio;
}
