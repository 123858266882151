import { Colors } from "@blueprintjs/core";
import { useParams } from "react-router-dom";

import { Box } from "@/components/layout/flexbox";
import ProgramTabs from "@/components/pages_logged_in/program/components/ProgramTabs";
import { RoleplayPageType } from "@/components/pages_logged_in/roleplays/details/roleplay-types";
import { Container } from "@/components/pieces/bootstrap";

export const ROLEPLAY_TABS_CONFIG = [
  {
    key: "analysis",
    label: "Analysis ",
    linkTo: "/roleplays/{roleplaySlug}/{scenarioSlug}/{sessionUuid}",
    secondLinkTo: "/scenarios/{scenarioSlug}/{sessionUuid}",
  },
  {
    key: "transcript",
    label: "Transcript ",
    linkTo: "/roleplays/{roleplaySlug}/{scenarioSlug}/{sessionUuid}/transcript",
    secondLinkTo: "/scenarios/{scenarioSlug}/{sessionUuid}/transcript",
  },
];

const COLLECTION_TABS_CONFIG = [
  {
    key: "details",
    label: "Details",
    linkTo: "/roleplays/{roleplaySlug}",
    secondLinkTo: undefined,
  },
  {
    key: "settings",
    label: "Evaluation Criteria",
    linkTo: "/roleplays/{roleplaySlug}/settings",
    secondLinkTo: undefined,
  },
];

export function getRoleplayTabs(
  roleplaySlug,
  scenarioSlug,
  sessionUuid,
  tabs = ROLEPLAY_TABS_CONFIG,
) {
  return tabs.map((item) => {
    let linkTo = item.linkTo || "";
    let secondLinkTo = item.secondLinkTo || "";

    if (roleplaySlug) {
      linkTo = linkTo.replace("{roleplaySlug}", roleplaySlug);
    }

    if (scenarioSlug) {
      if (roleplaySlug) {
        linkTo = linkTo.replace("{scenarioSlug}", scenarioSlug);
      } else {
        linkTo = secondLinkTo.replace("{scenarioSlug}", scenarioSlug);
      }
    }

    if (sessionUuid) {
      linkTo = linkTo.replace("{sessionUuid}", sessionUuid);
    }

    return { ...item, linkTo: linkTo };
  });
}

interface RoleplayTabsProps {
  roleplay?: any;
  scenario?: any;
  roleplaySession?: any;
  maxWidth?: number;
  page?: string;
  collectionPage?: boolean;
  view?: string;
  px?: number;
}

export default function RoleplayTabs({
  roleplay = null,
  scenario = null,
  roleplaySession = null,
  page,
  view = "analysis",
  px = 0,
}: RoleplayTabsProps) {
  const { sessionUuid, roleplaySlug, scenarioSlug } = useParams();

  const tabsConfig =
    page === RoleplayPageType.RoleplayDetail
      ? COLLECTION_TABS_CONFIG
      : ROLEPLAY_TABS_CONFIG;

  const tabs = getRoleplayTabs(
    roleplay?.slug || roleplaySlug,
    scenario?.slug || scenarioSlug,
    roleplaySession?.uuid || sessionUuid,
    tabsConfig,
  );
  const selectedTabe = tabs.find((tab) => tab.key === view) || tabs[0];

  return (
    <Box className="bbs" py={0} bg={Colors.WHITE} px={px}>
      <Container>
        <ProgramTabs tabs={tabs} initialTab={selectedTabe.key} marginTop={0} />
      </Container>
    </Box>
  );
}
